import {Menu} from 'semantic-ui-react';
import {RouteComponentProps, withRouter} from 'react-router-dom'


type items = {
    id: string,
    name: string,
    value: string,
    link: string
}

interface Props extends RouteComponentProps {
    items: items[]
  }


const Navigation = ({history, items}: Props) => {
    const url = window.location.pathname;
    const navPrefix = items.find(i => url.includes(i.value) || url.includes(i.id))?.value
    console.log(navPrefix)
    const activeItem = navPrefix === undefined ? "run-job" : navPrefix
    return (
        <Menu pointing secondary>
        <Menu.Menu position="right">
          {
            items.map(i=> 
              <Menu.Item
                key={i.id}
                name={i.name}
                active={i.value === activeItem}
                onClick={e => history.push(i.link)}
              />
            )
          }
        </Menu.Menu>
      </Menu>
    )

}

export default withRouter(Navigation);