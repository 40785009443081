import React from 'react';
import Navigation from "../../components/navigation";
import items from '../../assets/comparison-metadata.json';
import '../container.css'

type props = {
}
type state = {
    siteIds: [],
    loading: boolean
}

const {
    REACT_APP_STAGE,
    REACT_APP_SERVICE_DOMAIN,
    REACT_APP_SERVICE_KEY
} = process.env
class ComparisonHistory extends React.Component<props, state> {

    constructor(props: props) {
        super(props);
        this.state = {
            siteIds: [],
            loading: false
        }
    }

    componentDidMount = async () => {
        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
        fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/siteinfo/getSiteIds`, {
            headers,
            method: 'GET',
            mode: 'cors'
        }).then((res) => res.json()).then(json => this.setState({
            siteIds: json.response.siteIds
        }))
    }

    render() {
        return (
            <div className="onDemand-outerdiv">
                <Navigation items={items} />
                <div className={"max-width-history-table"}>
                <table className="ui striped blue table">
                    <thead>
                        <tr>
                            <th>Job Id</th>
                            <th>Site Id 1</th>
                            <th>Site Id 2</th>
                            <th>Services</th>
                            <th>E-mail</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><a href="https://google.com">1624558176test</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                        <tr>
                            <td><a href="https://google.com">1624558176</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                        <tr>
                            <td><a href="https://google.com">1624558176</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                        <tr>
                            <td><a href="https://google.com">1624558176</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                        <tr>
                            <td><a href="https://google.com">1624558176</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                        <tr>
                            <td><a href="https://google.com">1624558176</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                        <tr>
                            <td><a href="https://google.com">1624558176</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                        <tr>
                            <td><a href="https://google.com">1624558176</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                        <tr>
                            <td><a href="https://google.com">1624558176</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                        <tr>
                            <td><a href="https://google.com">1624558176</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                        <tr>
                            <td><a href="https://google.com">1624558176</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                        <tr>
                            <td><a href="https://google.com">1624558176</a></td>
                            <td>Site Id test 1</td>
                            <td>Site Id test 2</td>
                            <td>Accessibility Testing, Third Party Integration</td>
                            <td>jhlilk22@gmail.com</td>
                            <td>Thursday, June 24, 2021 6:09:36 PM</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        )
    }
}

export default ComparisonHistory;