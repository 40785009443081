import React from 'react';
import Navigation from "../../components/navigation";
import items from '../../assets/comparison-metadata.json';
import {services} from '../../assets/services-metadata';
import { Form, Button } from 'semantic-ui-react'
import '../container.css'

type props = {
}
type state = {
    siteIds: [],
    loading: boolean
}

const {
    REACT_APP_STAGE,
    REACT_APP_SERVICE_DOMAIN,
    REACT_APP_SERVICE_KEY
} = process.env

class ComparisonRunTask extends React.Component<props, state> {

    constructor(props: props) {
        super(props);
        this.state = {
            siteIds: [],
            loading: false
        }
    }

    componentDidMount = async () => {
        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
        fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/siteinfo/getSiteIds`, {
            headers,
            method: 'GET',
            mode: 'cors'
        }).then((res) => res.json()).then(json => this.setState({
            siteIds: json.response.siteIds
        }))
    }

    handleSubmit = async () => {
        this.setState({
            loading: true
        })
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 10000)
    }

    render() {
        return (
            <div className="onDemand-outerdiv">
                <Navigation  items={items} />
                <div className="max-width">
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Select
                                fluid
                                label='Select Site ID 1'
                                options={this.state.siteIds.map(item => {
                                    return {
                                        text: item,
                                        value: item
                                    }
                                })}
                                placeholder='Site Id 1'
                            />
                            <Form.Select
                                fluid
                                label='Select Site ID 2'
                                options={this.state.siteIds.map(item => {
                                    return {
                                        text: item,
                                        value: item
                                    }
                                })}
                                placeholder='Site Id 2'
                            />
                        </Form.Group>
                        <Form.Input
                            fluid
                            label='Enter Email on which the notification will be send'
                            placeholder='Email'
                            id='form-input-email'
                        />
                        <Form.Group grouped>
                            <label>Services</label>
                            {services.map(i => <Form.Field label={i.name} control='input' type='checkbox' />)}
                            <Form.Field label={'Lighthouse (Coming Soon)'} control='input' type='checkbox' disabled />
                            <Form.Field label={'SEO (Coming Soon)'} control='input' type='checkbox' disabled/>
                        </Form.Group>
                        <br />
                       <Button className={"submit"} onClick={this.handleSubmit} loading={this.state.loading}>Submit</Button> 
                    </Form>
                </div>
            </div>
        )
    }
}

export default ComparisonRunTask;