import React from 'react';
import Navigation from "../../components/navigation";
import { Message, Icon } from 'semantic-ui-react';
import items from '../../assets/trends-metadata.json';
import emails from '../../assets/emails.json';
import '../container.css';


type state = {
    siteIds: [],
    selectedSiteId: string,
    dateFilter: string,
    jobIdFilter: string
    loading: boolean,
    graphLoading: boolean,
    timeseries: {}[]
}


class Dashboard extends React.Component<{}, state> {

    constructor(props: {}) {
        super(props)
        this.state = {
            siteIds: [],
            selectedSiteId: "",
            dateFilter: "",
            jobIdFilter: "",
            loading: true,
            graphLoading: false,
            timeseries: []
        }
    }


    render() {
        return (
            <div >
                <Navigation items={items} />
                <div style={{ width: '70%', display: 'inline-block', textAlign: "left" }}>
                <Message
                size='large'
    icon='chart bar'
    header='QuickSight Dashboad'
    content={<span>Please visit Quick Site <a href='https://us-east-1.quicksight.aws.amazon.com/sn/start/analyses' target="_blank">Link</a> for all DIALS reports.</span>}
  />            
                <br />
                <br />
                
   <Message size='large' color='teal' icon>
    <Icon name='bullhorn' bulhorn />
    <Message.Content>
      <Message.Header>Dont have access to quicksight dashboard ?</Message.Header>
      <br />
      If you do not have access to quick site below please write to -
      <br />
      <Message.List>
          {emails.PermissionEmails.map(item => {
 return <Message.Item>{item.name} - <a href={'mailto:'+item.email}>{item.email}</a></Message.Item>
          })}
    </Message.List>
    </Message.Content>
   
  </Message>
  
  </div>
                <br />
            </div>
        )
    }
}

export default Dashboard;