import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from "../../components/navigation";
import items from '../../assets/ondemand-metadata.json';
import { Input } from 'semantic-ui-react';
import '../container.css'

type props = {
}
type state = {
    history: [],
    loading: boolean,
    pkFilter: string,
    skFilter: string,
    brandFilter: string,
    countryFilter: string,
    emailFilter: string,
    platformFilter: string
}

const {
    REACT_APP_STAGE,
    REACT_APP_SERVICE_DOMAIN,
    REACT_APP_SERVICE_KEY
} = process.env


class OnDemandHistory extends React.Component<props, state> {

    constructor(props: props) {
        super(props);
        this.state = {
            history: [],
            loading: false,
            pkFilter: "",
            skFilter: "",
            brandFilter: "",
            countryFilter: "",
            emailFilter: "",
            platformFilter: ""
        }
    }

    componentDidMount = async () => {
        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
        fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getHistory?executionType=on-demand&pageSize=50`, {
            headers,
            method: 'GET',
            mode: 'cors'
        }).then((res) => res.json()).then(json => this.setState({
            history: json.response.result.Items
        }))
    }

    render() {
        return (
            <div className="onDemand-outerdiv">
                <Navigation items={items} />
                {this.state.history.length === 0 ? <div className="ui segment">
                    <div className="ui active inverted dimmer">
                        <div className="ui text loader">Loading</div>
                    </div>
                    <p></p>
                </div> : <div className={"max-width-history-table"}>
                    <table className="ui striped blue table">
                        <thead>
                            <tr>
                                <th>Job Id</th>
                                <th>SiteId</th>
                                <th>Brand</th>
                                <th>Country</th>
                                <th>Email</th>
                                <th>Platform</th>
                                <th>Services</th>
                                <th>Execution Time</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <th><Input style={{ width: '90%', marginTop: '.4 em', marginBottom: '.4 em', marginRight: '.4 em' }} focus placeholder='Search...' onChange={(e) => this.setState({
                                    pkFilter: e.target.value
                                })} /></th>
                                <th><Input style={{ width: '90%', margin: '.4 em' }} focus placeholder='Search...' onChange={(e) => this.setState({
                                    skFilter: e.target.value
                                })} /></th>
                                <th><Input style={{ width: '90%', margin: '.4 em' }} focus placeholder='Search...' onChange={(e) => this.setState({
                                    brandFilter: e.target.value
                                })} /></th>
                                <th><Input style={{ width: '90%', margin: '.4 em' }} focus placeholder='Search...' onChange={(e) => this.setState({
                                    countryFilter: e.target.value
                                })} /></th>
                                <th><Input style={{ width: '90%', margin: '.4 em' }} focus placeholder='Search...' onChange={(e) => this.setState({
                                    emailFilter: e.target.value
                                })} /></th>
                                <th><Input style={{ width: '90%', margin: '.4 em' }} focus placeholder='Search...' onChange={(e) => this.setState({
                                    platformFilter: e.target.value
                                })} /></th>
                                <th></th>
                                <th></th>
                            </tr>
                            {this.state.history.filter((item: any) => (String(item.pk).includes(this.state.pkFilter) && item.sk.includes(this.state.skFilter) && item.brand.includes(this.state.brandFilter) && item.country.includes(this.state.countryFilter) && item.email.includes(this.state.emailFilter) && item.platform.includes(this.state.platformFilter))).map((item: any) => <tr>
                                <td>
                                    <Link to={`/on-demand/summary/${item.pk}`}>
                                        {item.pk}
                                    </Link>
                                    {/* <a href={`/on-demand/summary/${item.pk}`}>{item.pk}</a> */}
                                </td>
                                <td>{item.sk}</td>
                                <td>{item.brand}</td>
                                <td>{item.country}</td>
                                <td>{item.email}</td>
                                <td>{item.platform}</td>
                                <td>{item.services}</td>
                                <td>{`${new Date(item.pk).toDateString()} ${new Date(item.pk).toTimeString()} `}</td>
                            </tr>

                            )}
                        </tbody>
                    </table>
                </div>}

            </div>
        )
    }
}

export default OnDemandHistory;