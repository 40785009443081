function isBetween(highrange: Number, lowrange: Number, value: Number) {
  return highrange >= value && lowrange <= value;
}

export function getClassName(value: any) {
  let top = 100;
  let semi = 90;
  let mid = 50;
  let className = isBetween(top, semi, value)
    ? "text-green"
    : isBetween(semi, mid, value)
    ? "text-amber"
    : "text-red";

  return className;
}

export function getLcpFcpClsClassName(value: any, metric: any) {
  let top = 100;
  let mid = 50;
  let bottom = 0;
  switch (metric) {
    case "Fcp":
      top = 4;
      mid = 2;
      bottom = 0;
      break;
    case "Lcp":
      top = 4;
      mid = 2.5;
      bottom = 0;
      break;
    case "Cls":
      top = 0.25;
      mid = 0.1;
      bottom = 0;
      break;
  }

  let className = isBetween(mid, bottom, value)
    ? "text-green"
    : isBetween(top, mid, value)
    ? "text-amber"
    : "text-red";
  return className;
}
