import {FunctionComponent} from 'react';
import {
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryAxis,
  VictoryArea
} from 'victory';
import moment from 'moment';
import _ from 'lodash';


type timeseries = {}[]

const Graph: FunctionComponent<{timeseries: timeseries, days: number}> = ({ timeseries, days }) =>{
  const blue = '#1E90FF';
  const backGround = {
    green: '#7ED321',
    orange: '#ff9a00',
    red: '#D0021B'
  }
  let startX = moment().subtract(days, 'days').startOf('day').valueOf();
  let endX =  moment().startOf('day').valueOf();
  console.log(timeseries)
  const chartData = timeseries.map((v:any) => {
    return Object.assign({}, v, { x:moment(moment(v.jobId).format('YYYY-MM-DD')+"T00:00:00Z").startOf('day').valueOf() , y: v.performance , label: `${moment(v.jobId).format('ddd MMM DD')} - ${v.performance}`});
  }).filter((v: any) => v.performance > 0);
  console.log(chartData)
  let ticks;
  if (days <= 5) {
    ticks = _.range(4).map(v => moment(startX).add(v, "days").valueOf());
  }
  else if (days <= 10) {
    ticks = _.range(0,10, 2).map(v => moment(startX).add(v, "days").valueOf());
  }
  else {
    ticks = _.range(0,days+1,7).map(v => moment(startX).add(v, "days").valueOf());
  }
  return (
    <VictoryChart
      domain={{ y: [0, 100],  x: [startX, endX]}}
      padding={{ top: 5, bottom: 18, left: 17, right: 10 }}
      theme={VictoryTheme.material}
      height={70}
      containerComponent={
        <VictoryVoronoiContainer/>}
    >

      <VictoryAxis
        tickValues={ticks}
        tickFormat={x => { return moment(x).startOf('day').format('MMM DD')}}
        theme={VictoryTheme.material}
        style={{
          tickLabels: { fontSize: 5, padding: 1 },
          axis: { stroke: 'grey' },
          ticks: { stroke: 'grey', size: 2 }
        }}
      />
      <VictoryAxis
        dependentAxis
        style={{
          tickLabels: { fontSize: 5, padding: 0 },
          grid: { strokeWidth: 0 }
        }}
      />
      <VictoryLine
        style={{
          data: { stroke: blue, strokeWidth: 1 },
          parent: { border: '1px solid #ccc' },
          labels: { fontSize: 5, padding: 2, fontFamily: 'Helvetica Neue'},
        }}
        interpolation="natural"
        animate={{ duration: 4000 }}
        data={chartData}
        labelComponent={<VictoryTooltip />}
      />
      <VictoryArea
        style={{data: {fill: backGround.green, fillOpacity:0.2, stroke: "unset"}}}
        data = {[{ x: startX, y:90, y0: 100 },
          { x: endX, y: 90, y0: 100 },]}
      />
      <VictoryArea
        style={{data: {fill: backGround.orange, fillOpacity:0.2, stroke: "unset"}}}
        data = {[{ x: startX, y:50, y0: 90 },
          { x: endX, y: 50, y0: 90 },]}
      />
      <VictoryArea
        style={{data: {fill: backGround.red, fillOpacity:0.2, stroke: "unset"}}}
        data = {[{ x: startX, y:0, y0: 49 },
          { x: endX, y: 0, y0: 49 },]}
      />
    </VictoryChart>
  )
}



export default Graph;
