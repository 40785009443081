import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Grid, Placeholder, Segment, Card } from "semantic-ui-react";
import Tile from "../../components/performanceTile";
import { services, colorBlindness } from "../../assets/services-metadata";

interface params {
  jobId: string;
}
interface props extends RouteComponentProps<params> { }
type state = {
  data: any[];
  loading: boolean;
  lighthouseLoading: boolean;
  lighthouseCableCount: string | number;
  lighthouse3gCount: string | number;
  lighthouseSeoCount: string | number;
};

const {
  REACT_APP_STAGE,
  REACT_APP_SERVICE_DOMAIN,
  REACT_APP_SERVICE_KEY,
  REACT_APP_SERVICE_LIGHTHOUSE_DOMAIN,
  REACT_APP_SERVICE_LIGHTHOUSE_KEY,
} = process.env;

class OnDemandSummary extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      lighthouseLoading: true,
      lighthouseCableCount: 0,
      lighthouse3gCount: 0,
      lighthouseSeoCount: 0,
    };
  }


  getLighthouseUrls = (mode: string) => {
    let { jobId } = this.props.match.params;
    const headers = new Headers();
    headers.append("x-api-key", REACT_APP_SERVICE_LIGHTHOUSE_KEY!);
    if (mode === "lighthouse-3g") {
      fetch(
        `${REACT_APP_SERVICE_LIGHTHOUSE_DOMAIN}/${REACT_APP_STAGE}/getCount/${jobId}/3g`,
        {
          headers,
          method: "GET",
          mode: "cors",
        }
      )
        .then((res) => res.json())
        .then((json) =>
          this.setState({
            lighthouse3gCount: json.response[0].count,
            lighthouseLoading: false,
          })
        );
    } else if (mode === "lighthouse-cable") {
      fetch(
        `${REACT_APP_SERVICE_LIGHTHOUSE_DOMAIN}/${REACT_APP_STAGE}/getCount/${jobId}/cable`,
        {
          headers,
          method: "GET",
          mode: "cors",
        }
      )
        .then((res) => res.json())
        .then((json) =>
          this.setState({
            lighthouseCableCount: json.response[0].count,
            lighthouseLoading: false,
          })
        );
    } else {
      fetch(
        `${REACT_APP_SERVICE_LIGHTHOUSE_DOMAIN}/${REACT_APP_STAGE}/getCount/${jobId}/seo`,
        {
          headers,
          method: "GET",
          mode: "cors",
        }
      )
        .then((res) => res.json())
        .then((json) =>
          this.setState({
            lighthouseSeoCount: json.response[0].count,
            lighthouseLoading: false,
          })
        );
    }
  };

  componentDidMount = () => {
    let { jobId } = this.props.match.params;
    const headers = new Headers();
    headers.append("x-api-key", REACT_APP_SERVICE_KEY!);
    fetch(
      `${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getJobStatus/${jobId}`,
      {
        headers,
        method: "GET",
        mode: "cors",
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          data: json.response.result.Items,
          loading: false,
        })
      }

      );
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (prevState.data !== this.state.data) {
      services.map((service) =>
        service.id.includes("lighthouse") && this.state.data[0][service.id] !== undefined ?
          (this.state.lighthouseLoading ? this.getLighthouseUrls(service.id) : null) : null
      )
    }
  }




  getCount = (mode: string) => {
    switch (mode) {
      case "lighthouse-3g":
        return this.state.lighthouse3gCount;
      case "lighthouse-cable":
        return this.state.lighthouseCableCount;
      case "lighthouse-seo":
        return this.state.lighthouseSeoCount;
    }
    return 0;
  };

  render() {
    return (
      <div className={"grid-wrapper"}>
        {this.state.loading ? (
          <Grid columns={2} divided>
            <Grid.Column>
              <Segment raised>
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment raised>
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
          </Grid>
        ) : (
          <div>
            <Card style={{ width: "75%" }}>
              <Card.Content>
                <Card.Description>
                  <div>
                    <Grid columns={2}>
                      <Grid.Column>
                        <div style={{ display: "inline-grid" }}>
                          <span style={{ display: "inline-flex" }}>
                            <h4>Site Id - </h4>
                            {this.state.data[0].sk}{" "}
                          </span>
                          <span style={{ display: "inline-flex" }}>
                            <h4>Brand - </h4>
                            {this.state.data[0].brand}{" "}
                          </span>
                          <span style={{ display: "inline-flex" }}>
                            <h4>Category - </h4>
                            {this.state.data[0].division}{" "}
                          </span>
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <div style={{ display: "inline-grid" }}>
                          <span style={{ display: "inline-flex" }}>
                            <h4>Job Id - </h4>
                            {this.state.data[0].pk}{" "}
                          </span>
                          <span style={{ display: "inline-flex" }}>
                            <h4>Country - </h4>
                            {this.state.data[0].country}{" "}
                          </span>
                          <span style={{ display: "inline-flex" }}>
                            <h4>SubCategory - </h4>
                            {this.state.data[0].subCategory}{" "}
                          </span>
                        </div>
                      </Grid.Column>
                    </Grid>
                  </div>
                </Card.Description>
              </Card.Content>
            </Card>
            <Grid
              columns={this.state.data[0].totalServices >= 2 ? 2 : 1}
              divided
            >
              {services.map((service) => {
                return service.id.includes("lighthouse") &&
                  this.state.data[0][service.id] !== undefined ? (
                  this.state.lighthouseLoading ? (
                    <Grid.Column>
                      <Segment raised>
                        {/* {//this.getLighthouseUrls(service.id)} */}
                        <Placeholder>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                          <Placeholder.Paragraph>
                            <Placeholder.Line length="medium" />
                            <Placeholder.Line length="short" />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </Segment>
                    </Grid.Column>
                  ) : (
                    <Grid.Column>
                      <div>
                        <Tile
                          item={{
                            cardHeader: service.name,
                            link:
                              this.getCount(service.id) === 0
                                ? ""
                                : `${service.id}/detailedAnalysis/${this.state.data[0].pk}`,
                            meta: (
                              <span>
                                {this.getCount(service.id) === 0 ? (
                                  <p style={{ color: "red" }}>in-queue</p>
                                ) : (
                                  <p style={{ color: "green" }}>
                                    {" "}
                                    {this.getCount(service.id) +
                                      " Urls completed execution"}
                                  </p>
                                )}
                              </span>
                            ),
                            description: service.description(
                              this.state.data[0]
                            ),
                          }}
                        />
                      </div>
                    </Grid.Column>
                  )
                ) : this.state.data[0][service.id] !== undefined ? (
                  <Grid.Column>
                    <div>
                      <Tile
                        item={{
                          cardHeader: service.name,
                          link:
                            typeof this.state.data[0][service.id] === "string"
                              ? ""
                              : `${service.id}/detailedAnalysis/${this.state.data[0].pk}?siteId=${this.state.data[0].sk}`,
                          meta:
                            typeof this.state.data[0][service.id] ===
                              "string" ? (
                              <span style={{ color: "red" }}>
                                {this.state.data[0][service.id]}
                              </span>
                            ) : (
                              <span style={{ color: "green" }}>Executed</span>
                            ),
                          description: service.description(this.state.data[0]),
                        }}
                      />
                    </div>
                  </Grid.Column>
                ) : null;
              })}
              {colorBlindness.map((service) =>
                this.state.data[0][service.id] !== undefined ? (
                  <Grid.Column>
                    <div>
                      <Tile
                        item={{
                          cardHeader: service.name,
                          link:
                            this.state.data[0][service.id].s3Path !== undefined
                              ? `color-blind-test-att/detailedAnalysis/${this.state.data[0].pk}?test=${service.id}&siteId=${this.state.data[0].sk}`
                              : "",
                          meta:
                            this.state.data[0][service.id].s3Path !==
                              undefined ? (
                              <span style={{ color: "green" }}>Executed</span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {this.state.data[0][service.id]}
                              </span>
                            ),
                          description: service.description(this.state.data[0]),
                        }}
                      />
                    </div>
                  </Grid.Column>
                ) : null
              )}
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(OnDemandSummary);
