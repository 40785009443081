import { FunctionComponent } from 'react';
import { Menu, Icon, MenuHeader } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { LoggedInContext } from '../Login-Context'
import { auth } from "../components/firebase";
import { useSignOut } from 'react-firebase-hooks/auth';


const Header: FunctionComponent<{ prefix: string, handlePrefix: Function }> = ({ prefix, handlePrefix }) => {

  const { loggedInUser, setLoggedInUser } = useContext(LoggedInContext);
  const { isLoggedIn } = loggedInUser
  const [signOut, loading, error] = useSignOut(auth);

  return (
    <Menu id="menu" icon="labeled" borderless fluid>
      <MenuHeader>
        <span>
          <Link to={isLoggedIn ? "/on-demand/run-job" : "/"}>
            <Icon name="home" inverted color="grey" />
          </Link>
        </span>
        <span> Dials Reporting </span>
      </MenuHeader>
      <Menu.Menu className="options">
        {isLoggedIn && <Menu.Item
          name="On Demand"
          as={NavLink}
          exact
          onClick={() => handlePrefix('on-demand')}
          active={'on-demand' === prefix}
          to={`/on-demand`}
        >
          <Icon name="play" inverted color="grey" />
          On Demand Execution
        </Menu.Item>}
        {/* {isLoggedIn && <Menu.Item
          name="Trends"
          as={NavLink}
          exact
          onClick={() => handlePrefix('trends')}
          active={'trends' === prefix}
          to={`/trends/dashboard`}
        > <Icon name="chart bar" inverted color="grey" />
          Trends
        </Menu.Item>} */}
        {isLoggedIn && <Menu.Item
          name="Logout"
          as={NavLink}
          exact
          onClick={async () => {
            await signOut();
            localStorage.removeItem('loginData')
            localStorage.removeItem('sessionUser')
            setLoggedInUser({ isLoggedIn: false, user: undefined })
          }}
          to={`/`}
        >
          <Icon name="power" />
          Logout
        </Menu.Item>}
        {/* <Menu.Item
            name="Comparison Runs"
            as={NavLink}
            exact
            onClick={() => handlePrefix('comparison')}
            active={'comparison' === prefix}
            to={`/comparison`}
          >
            <Icon name="chart line" inverted color="grey" />
            Comparison Runs
          </Menu.Item>
          <Menu.Item
            name="Daily Runs (Lighthouse)"
            as={NavLink}
            exact
            onClick={() => handlePrefix('mass-execution')}
            active={'mass-execution' === prefix}
            to={`/mass-execution`}
          > <Icon name="calendar alternate outline" inverted color="grey" />
           Daily Runs (Lighthouse)
        </Menu.Item> */}
      </Menu.Menu>
    </Menu>
  );
}


export default Header;
