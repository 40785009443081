import React from 'react';
import {
    withRouter,
    RouteComponentProps
} from 'react-router-dom'
import { Grid, Placeholder, Segment, Card } from 'semantic-ui-react';
import Tile from '../../components/performanceTile'
import services from '../../assets/lighthouse-services-metadata';

interface params {
    siteId: string;
}
interface props extends RouteComponentProps<params> {
}
type state = {
    data: {
        [key: string]: any
    },
    loading: boolean
}

const {
    REACT_APP_STAGE,
    REACT_APP_SERVICE_LIGHTHOUSE_DOMAIN,
    REACT_APP_SERVICE_LIGHTHOUSE_KEY
} = process.env

class MassExecutionSummary extends React.Component<props, state> {
    constructor(props: props) {
        super(props)
        this.state = {
            data: {},
            loading: true
        }
    }

    componentDidMount = () => {
        let siteId = (new URLSearchParams(this.props.location.search)).get("siteId")!
        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_LIGHTHOUSE_KEY!);
        fetch(`${REACT_APP_SERVICE_LIGHTHOUSE_DOMAIN}/${REACT_APP_STAGE}/getDailyRunData/3g?siteId=${siteId}`, {
            headers,
            method: 'GET',
            mode: 'cors'
        }).then((res) => res.json()).then(json => this.setState({
            data: json,
            loading: false
        }))
        // setTimeout(() => {
        //     this.setState({
        //         loading: false,
        //         data: {
        //             PageUrl: `https://www.dove.com/in/home.html`,
        //             JobId: 1627929252962,
        //             Brand: `Dove`,
        //             Locale: `India`,
        //             SiteId: siteId,
        //             Mode: `3g`,
        //             Fcp: 1.7,
        //             Lcp: 4.6,
        //             Cls: 0,
        //             SpeedIndex: 3.7,
        //             TimeToInteractive: 6.7,
        //             TotalBlockingTime: 0.7,
        //             ObservedLoad: 3.2,
        //             Performance: 23,
        //             Accessibility:100,
        //             BestPractices: 67,
        //             Seo: 100,
        //         }
        //     })
        // }, 5000)
    }


    render() {
        return (
            <div className={"grid-wrapper"}>
                {this.state.loading ? (

                    <Grid columns={2} divided>
                        <Grid.Column>
                            <Segment raised>
                                <Placeholder>
                                    <Placeholder.Header>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line length='medium' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment raised>
                                <Placeholder>
                                    <Placeholder.Header>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line length='medium' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                        </Grid.Column>
                    </Grid>) : (<div>
                        <Card style={{ "width": "75%" }}>
                            <Card.Content>
                                <Card.Description>
                                    <div>
                                        <Grid columns={2}>
                                            <Grid.Column>
                                                <div style={{ display: 'inline-grid' }}>
                                                    <span style={{ display: 'inline-flex' }}><h4>Site Id - </h4>{this.state.data.SiteId} </span>
                                                    <span style={{ display: 'inline-flex' }}><h4>Brand - </h4>{this.state.data.Brand} </span>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <div style={{ display: 'inline-grid' }}>
                                                    <span style={{ display: 'inline-flex' }}><h4>Job Id - </h4>{this.state.data.JobId}  </span>
                                                    <span style={{ display: 'inline-flex' }}><h4>Country - </h4>{this.state.data.Locale}  </span>
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                    </div>
                                </Card.Description>
                            </Card.Content>
                        </Card>
                        <Grid columns={2} verticalAlign="top" stretched divided>
                            {services.map(service => {
                                return this.state.data[service.id] !== undefined ?
                                    <Grid.Column>
                                        <div><Tile item={{ 'cardHeader': service.name, link: "", meta:<span style={{ 'color': `${service.getColor(this.state.data[service.id])}`}}><h2>{this.state.data[service.id] === null ? "N/A":this.state.data[service.id].toFixed(2)}</h2></span>, description: service.description }} /></div>
                                    </Grid.Column>
                                    : null
                            }

                            )}
                        </Grid></div>)
                }
            </div>
        )
    }
}

export default withRouter(MassExecutionSummary);

