import React from 'react';
import Navigation from "../../components/navigation";
import items from '../../assets/trends-metadata.json';
import Graph from '../../components/graph';
import moment from 'moment'
import { Dropdown, Card, Segment, Header, Loader, Input } from 'semantic-ui-react';
import '../container.css';


type state = {
    siteIds: [],
    selectedSiteId: string,
    dateFilter: string,
    jobIdFilter: string
    loading: boolean,
    graphLoading: boolean,
    timeseries: {}[]
}

const {
    REACT_APP_STAGE,
    REACT_APP_SERVICE_DOMAIN,
    REACT_APP_SERVICE_KEY,
    REACT_APP_SERVICE_LIGHTHOUSE_DOMAIN,
    REACT_APP_SERVICE_LIGHTHOUSE_KEY
} = process.env

class TrendsGraphLighthouse extends React.Component<{}, state> {

    constructor(props: {}) {
        super(props)
        this.state = {
            siteIds: [],
            selectedSiteId: "",
            dateFilter: "",
            jobIdFilter: "",
            loading: true,
            graphLoading: false,
            timeseries: []
        }
    }

    componentDidMount = () => {
        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
        fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/siteinfo/getFullSiteIds`, {
            headers,
            method: 'GET',
            mode: 'cors'
        }).then((res) => res.json()).then(json => this.setState({
            siteIds:  json.response.siteIds.filter((item: any) => item.cxx),
            loading: false
        }))
    }

    componentDidUpdate = (prevProps?: any ,prevState?: any) => {
        if (prevState.selectedSiteId !== this.state.selectedSiteId) {
            const headers = new Headers();
            headers.append('x-api-key', REACT_APP_SERVICE_LIGHTHOUSE_KEY!);
            this.setState({
                graphLoading: true
            })
            fetch(`${REACT_APP_SERVICE_LIGHTHOUSE_DOMAIN}/${REACT_APP_STAGE}/getDaysData/3g/30?siteId=${this.state.selectedSiteId}`, {
                headers,
                method: 'GET',
                mode: 'cors'
            }).then((res) => res.json()).then(json => this.setState({
                timeseries: json.map((item: any) => {
                    return {
                        jobId: item.JobId,
                        date: moment(item.JobId).format("DD MMM YYYY"),
                        performance: item.Performance == null? "N/A": Number(item.Performance.toFixed(2))
                    }
                }),
                graphLoading: false,
                loading: false
            }))
        }
    }

    handleDropDown = async (e: any, { value }: any) => {
        this.setState({
            selectedSiteId: value
        })
    }
    render() {
        return (
            <div >
                <Navigation items={items} />
                <div style={{ width: '70%', display: 'inline-block' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className="dashboard-header">
                            <h2 className="ui header">
                                Dashboard Overview
                                <div className="sub header">Last job ran on 5:30 GMT</div>
                            </h2>
                        </span>
                        <span className="dashboard-header-right-align">
                            <Dropdown
                                loading={this.state.loading}
                                floating
                                placeholder='Site Id'
                                fluid
                                search
                                selection
                                onChange={this.handleDropDown.bind(this)}
                                options={this.state.siteIds.map((item: any) => {
                                    return {
                                        text: item.siteId,
                                        value: item.siteId
                                    }
                                })}
                            />
                        </span>

                    </div>
                    <br />
                    <br />
                    <br />

                    {this.state.selectedSiteId.length > 0 ? (<div> <div >
                        <Card style={{ width: '100%' }} >
                            <Card.Header>
                                {this.state.selectedSiteId}
                            </Card.Header>
                            <Card.Description>
                                <Segment raised>
                                    <Header>
                                        <div>
                                            Lighthouse Performance Score(3G)

                                        </div>
                                    </Header>
                                    <Header id="chartSubTitle" size="tiny" color="grey">
                                        Last 30 Days
                                    </Header>
                                    
                                    {(this.state.timeseries && this.state.timeseries.length > 0)?  (
                                        <Graph timeseries={this.state.timeseries} days={7} />
                                    ): null}
                                </Segment>
                            </Card.Description>
                        </Card>
                    </div>
                        <br />
                        <br />
                        <br />
                        <div style={{ boxShadow: '0px 0px 10px 1px #888888' }} >
                            <table className="ui striped blue table">
                                <thead>
                                    <tr>
                                        <th>Job Id</th>
                                        <th>Date</th>
                                        <th>Performance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        <th><Input style={{ width: '90%', marginTop: '.4 em', marginBottom: '.4 em', marginRight: '.4 em' }} focus placeholder='Search...' onChange={(e) => this.setState({
                                            jobIdFilter: e.target.value
                                        })} /></th>
                                        <th><Input style={{ width: '90%', marginTop: '.4 em', marginBottom: '.4 em', marginRight: '.4 em' }} focus placeholder='Search...' onChange={(e) => this.setState({
                                            dateFilter: e.target.value
                                        })} /></th>
                                        <th></th>
                                        
                                    </tr>
                                    {(!this.state.graphLoading)?(
                                              this.state.timeseries.filter((item: any) => (String(item.JobId).includes(this.state.jobIdFilter) && item.date.includes(this.state.dateFilter))).map((item: any) => <tr>
                                                <td><a href={`/demo`}>{item.jobId}</a></td>
                                                <td>{item.date}</td>
                                                <td>{(item.performance === null || item.performance === undefined  ) ? "N/A" : item.performance}</td>
                                            </tr>
                                            )):<Loader active ={this.state.graphLoading} />
                                    }
                                </tbody>
                            </table>
                        </div></div>) : <div>
                            Please select a Site ID
                            </div>}

                </div>
                <br />
                <br />
                <br />
            </div>
        )
    }
}

export default TrendsGraphLighthouse;