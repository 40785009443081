import { FunctionComponent } from "react"
import { Card } from "semantic-ui-react";
// import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
type props = {
    item: {
        link: string,
        cardHeader: string,
        meta: string | JSX.Element,
        description: string
    }
}



const Tile: FunctionComponent<props> = (props: props) => {
    const history = useHistory();

    const routeChange = () => {
        history.push(props.item.link);
    }
    return (
        <div>
            <Card
                color='blue'
                //  href={props.item.link}
                onClick={routeChange}
                // meta={props.item.meta}
                description={props.item.description}>
                <Card.Content>
                    <Card.Header>
                        {props.item.cardHeader}
                    </Card.Header>
                    <Card.Meta>
                        {props.item.meta}
                    </Card.Meta>
                    <Card.Description>
                        {props.item.description}
                    </Card.Description>

                </Card.Content>
            </Card>
        </div>
    )
}

export default Tile;