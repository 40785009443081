const RED = '#FF0000';
const AMBER = '#f2711c';
const GREEN = '#21ba45';
const NOT_AVAILABLE_COLOUR = '#D3D3D3';


function getMainMetricsColor(average : number){
    let averageColour = NOT_AVAILABLE_COLOUR;
  if (isRed(average)) {
    averageColour = RED;
  }
  if (isAmber(average)) {
    averageColour = AMBER;
  }
  if (isGreen(average)) {
    averageColour = GREEN;
  }
  return averageColour;
}

function getLcpColor(value : number){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 2.5){
    color = GREEN
  }else if(value > 2.5 && value <=4){
    color  = AMBER
  }else if(value > 4){
    color = RED
  }
  return color
}

function getClsColor(value : number){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 0.1){
    color = GREEN
  }else if(value > 0.1 && value <=0.25){
    color  = AMBER
  }else if(value > 0.25){
    color = RED
  }
  return color
}

function getTbtColor(value : number){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 0.3){
    color = GREEN
  }else if(value > 0.3 && value <=0.6){
    color  = AMBER
  }else if(value > 0.6){
    color = RED
  }
  return color
}
function getTtiColor(value : number){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 3.8){
    color = GREEN
  }else if(value > 3.8 && value <=7.3){
    color  = AMBER
  }else if(value > 7.3){
    color = RED
  }
  return color
}
function getFcpColor(value : number){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 2){
    color = GREEN
  }else if(value > 2 && value <=4){
    color  = AMBER
  }else if(value > 4){
    color = RED
  }
  return color
}
function getSiColor(value : number){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 4.3){
    color = GREEN
  }else if(value > 4.3 && value <=5.8){
    color  = AMBER
  }else if(value > 5.8){
    color = RED
  }
  return color
}
function getFmpColor(value: number){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value < 5){
    color = GREEN
  }else if(value >= 5 && value < 10){
    color  = AMBER
  }else if(value >= 10){
    color = RED
  }
  return color
}
const isGreen = (average : number) => {
  return average <= 100 && average >= 90;
};

const isAmber = (average : number) => {
  return average >= 50 && average < 90;
};

const isRed = (average : number) => {
  return average < 50 && average > 0 ;
};

export { getMainMetricsColor, getLcpColor, getClsColor ,getTbtColor ,getTtiColor , getFcpColor ,getSiColor ,getFmpColor, RED, AMBER, GREEN };
