import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { auth } from "../../components/firebase";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import "./Login.css";
import { useContext } from 'react';
import { LoggedInContext } from '../../Login-Context'


const Login = ({ redirectTo }: {
  redirectTo: string;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loggedInUser, setLoggedInUser } = useContext(LoggedInContext);
  const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);

  useEffect(() => {
    const userJson: any = user?.user.toJSON()
    const accessToken = userJson?.stsTokenManager?.accessToken
    const isLoggedIn = user?.user ? true : false
    if (accessToken) {
      localStorage.setItem('loginData', accessToken)
      localStorage.setItem('sessionUser', JSON.stringify(user?.user))
    }
    return setLoggedInUser({ isLoggedIn: isLoggedIn, user: user?.user });
  }, [setLoggedInUser, user]);

  if (error) {
    return (
      <div>
        <p>Error: {error.message}</p>
      </div>
    );
  }
  if (loading) {
    return <p>Loading...</p>;
  }
  if (user) {
    return <Redirect to={redirectTo || "/on-demand/run-job"} />
  }
  return (
    <div className="login">
      <div className="login-main">
        <div className="switch" id="switch-cnt">
          <div className="switch__circle"></div>
          <div className="switch__circle switch__circle--t"></div>
          <div className="switch__container" id="switch-c1">
            <h2 className="switch__title title">Welcome !</h2>
            <p className="switch__description description">Please login to access the DIALS dashboard</p>
            <button className="switch__button button switch-btn" onClick={() => signInWithGoogle()}>Sign In with Google</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
