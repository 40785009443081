import React from 'react';
import {
    withRouter,
    RouteComponentProps
} from 'react-router-dom'
//import SummaryAccordion from '../../components/accordion'
import {services} from '../../assets/services-metadata'
import { Grid, Placeholder, Segment, Card, Button, Loader, Table,Divider } from 'semantic-ui-react'
import {getClassName,getLcpFcpClsClassName} from '../../helper/average'
type params = {
    jobId: string,
}
interface props extends RouteComponentProps<params> {

}

type state = {
    metadata: any,
    loading: boolean,
    service: string,
    data: any[],
    count: number
}


const {
    REACT_APP_STAGE,
    REACT_APP_SERVICE_LIGHTHOUSE_DOMAIN,
    REACT_APP_SERVICE_LIGHTHOUSE_KEY,
    REACT_APP_SERVICE_DOMAIN,
    REACT_APP_SERVICE_KEY

} = process.env

class OnDemandDetailedAnalysisLHCable extends React.Component<props, state>{

    constructor(props: props) {
        super(props)
        this.state = {
            metadata: {},
            loading: true,
            service: "Lighthouse - Cable",
            count: 0,
            data: []
        }
    }

    componentDidMount = () => {
        let { jobId } = this.props.match.params
        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
        const lighthouseHeaders = new Headers();
        lighthouseHeaders.append('x-api-key', REACT_APP_SERVICE_LIGHTHOUSE_KEY!);
        fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getJobStatus/${jobId}`, {
            headers,
            method: 'GET',
            mode: 'cors'
        }).then((res) => res.json()).then(json => this.setState({
            metadata: json.response.result.Items,
            loading: false
        }))
        fetch(`${REACT_APP_SERVICE_LIGHTHOUSE_DOMAIN}/${REACT_APP_STAGE}/getCount/${jobId}/cable`, {
            headers: lighthouseHeaders,
            method: 'GET',
            mode: 'cors'
        }).then((res) => res.json()).then(json => this.setState({
            count: json.count,
        }))
        fetch(`${REACT_APP_SERVICE_LIGHTHOUSE_DOMAIN}/${REACT_APP_STAGE}/results/getRows/${jobId}/cable`, {
            headers : lighthouseHeaders,
            method: 'GET',
            mode: 'cors'
        }).then((res) => res.json()).then(json => this.setState({
            data: json,
           
        }))
    }

    async getCsv() {
        const csv = await this.generateCsv();
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.download = `test_runs.csv`;
        link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        link.click();
      }
      async generateCsv() {
        let data = this.state.data;
        let csv = '';
        let features = ['PageUrl','Performance', 'Accessibility', 'Seo','BestPractices','SpeedIndex', 'Cls','ObservedLoad','TotalBlockingTime','TimeToInteractive','Fcp','Lcp'];
        for (let feature of features) {
          csv = `${csv}${feature},`;
        }
        csv = `${csv}\r\n`;
        for (let item of data) {
          for (let feature of features) {
            csv = `${csv}${item[feature]},`;
          }
          csv = `${csv}\r\n`;
        }
        return csv;
      }

    render() {
        return (
            <div className="detailed-report-accordion">
                {this.state.loading ?
                    <Grid>
                        <Grid.Column>
                            <Segment raised>
                                <Placeholder>
                                    <Placeholder.Header>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line length='medium' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                        </Grid.Column>
                    </Grid> :
                    (
                        <div>
                            <div className={"summary-card"}>
                                <Card color='blue' style={{width: "60%"}}>
                                    <Card.Header>
                                    <div>
                                    <br/>
                                        <Grid columns={2}>
                                        <Grid.Column>
                                            <div style={{display: 'inline-grid'}}>
                                            <span style={{ display: 'inline-flex' }}><h4>Site Id - </h4>{this.state.metadata[0].sk} </span>
                                            <span style={{ display: 'inline-flex' }}><h4>Brand - </h4>{this.state.metadata[0].brand} </span>
                                            <span style={{ display: 'inline-flex' }}><h4>Category - </h4>{this.state.metadata[0].division} </span>
                                            </div>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <div style={{display: 'inline-grid'}}>
                                                <span style={{ display: 'inline-flex' }}><h4>Job Id - </h4>{this.state.metadata[0].pk}  </span>
                                                <span style={{ display: 'inline-flex' }}><h4>Country - </h4>{this.state.metadata[0].country}  </span>
                                                <span style={{ display: 'inline-flex' }}><h4>SubCategory - </h4>{this.state.metadata[0].subCategory}  </span>
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                        </div>
                                    </Card.Header>
                                    <Card.Meta>
                                        <Divider/>
                                    </Card.Meta>
                                    <Card.Description>
                                        <h3 style={{margin:"0px"}}>{(services.find(service => service.id === this.state.service))?.name}</h3>
                                        <h3 style={{margin:"0px"}}>{`${this.state.count} urls executed`}</h3>
                                    </Card.Description>
                                    <Card.Content extra>
                                        
                                        <div className='ui two buttons'>
                                            <Button basic color='green' onClick={() => this.getCsv()} >
                                                Download Report
                                            </Button>
                                        </div>
                                    </Card.Content>
                                </Card>
                            </div>
                            <div className={"detail-table"}>
                                { this.state.data.length > 0 ? (

                                    <Table striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell className='lighthouse-table-head'>PageUrl</Table.HeaderCell>
                                                <Table.HeaderCell className='lighthouse-table-head'>Performance</Table.HeaderCell>
                                                <Table.HeaderCell className='lighthouse-table-head'>Accessibility</Table.HeaderCell>
                                                <Table.HeaderCell className='lighthouse-table-head'>BestPractices</Table.HeaderCell>
                                                <Table.HeaderCell className='lighthouse-table-head'>Seo</Table.HeaderCell>
                                                <Table.HeaderCell className='lighthouse-table-head'>Fcp</Table.HeaderCell>
                                                <Table.HeaderCell className='lighthouse-table-head'>Lcp</Table.HeaderCell>
                                                <Table.HeaderCell className='lighthouse-table-head'>Cls</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {this.state.data.map((item: any) => <Table.Row>
                                                <Table.Cell>
                                                    {item.PageUrl}
                                                </Table.Cell>
                                                <Table.Cell className={getClassName(item.Performance)}>
                                                    {item.Performance}
                                                </Table.Cell>
                                                <Table.Cell className={getClassName(item.Accessibility)}>
                                                     {item.Accessibility}
                                                </Table.Cell >
                                                <Table.Cell className={getClassName(item.BestPractices)}>
                                                {item.BestPractices}
                                                </Table.Cell>
                                                <Table.Cell className={getClassName(item.Seo)}>
                                                    {item.Seo}
                                                </Table.Cell>
                                                <Table.Cell className={getLcpFcpClsClassName(item.Fcp,"Fcp")}>
                                                {item.Fcp}
                                                </Table.Cell >
                                                <Table.Cell className={getLcpFcpClsClassName(item.Lcp,"Lcp")}>
                                                {item.Lcp}
                                                </Table.Cell>
                                                <Table.Cell className={getLcpFcpClsClassName(item.Cls,"Cls")}>
                                                {item.Cls}
                                                </Table.Cell>
                                            </Table.Row>)}
                                        </Table.Body>
                                    </Table>

                                ) : <Loader />}
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default withRouter(OnDemandDetailedAnalysisLHCable);