import React from 'react';
import {
    withRouter,
    RouteComponentProps
} from 'react-router-dom'
//import SummaryAccordion from '../../components/accordion'
import { services } from '../../assets/services-metadata'
import { Grid, Placeholder, Segment, Card, Table, Divider, Loader, Button, Modal, Image } from 'semantic-ui-react'

type params = {
    jobId: string,
}
interface props extends RouteComponentProps<params> {

}

type state = {
    data: {
        OriginalObjects: number,
        CbObjects: number,
        Url: string,
    }[],
    test: string,
    loading: boolean,
    service: string,
    siteId: string,
    jobId: string,
    downloadImageLoading: string,
    viewImageLoading: string,
    imageSrc: string,
    viewImage: boolean
}


const {
    REACT_APP_STAGE,
    REACT_APP_SERVICE_DOMAIN,
    REACT_APP_SERVICE_KEY
} = process.env

class OnDemandDetailedAnalysisCB extends React.Component<props, state>{

    constructor(props: props) {
        super(props)
        this.state = {
            data: [{
                OriginalObjects: 0,
                CbObjects: 0,
                Url: '',
            }],
            loading: true,
            service: "color-blindness-testing",
            downloadImageLoading: "",
            viewImageLoading: "",
            viewImage: false,
            test: "",
            imageSrc: "",
            siteId: "",
            jobId: ""
        }
    }

    download: Function = async (downloadablePath: string) => {
        try {
            const link = document.createElement("a");
            link.href = downloadablePath;
            link.setAttribute(
                "download",
                `image-${this.state.service}`
            );
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            console.log(e)
        }
    }

    handleImageDownload: Function = async (s3Path: string) => {
        try {
            this.setState({
                downloadImageLoading: s3Path
            })
            const headers = new Headers();
            headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
            let s3Url = await (await fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getSignedUrlImage`, {
                headers,
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    s3Path
                })
            })).json();
            this.download(s3Url.response.signedPath)
            this.setState({
                downloadImageLoading: ""
            })
        } catch (e) {
            console.log(e)
        }
    }

    handleImageView: Function = async (s3Path: string) => {
        try {
            this.setState({
                viewImageLoading: s3Path
            })
            const headers = new Headers();
            headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
            let s3Url = await (await fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getSignedUrlImage`, {
                headers,
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    s3Path
                })
            })).json();

            this.setState({
                viewImageLoading: "",
                viewImage: true,
                imageSrc: s3Url.response.signedPath
            })
        } catch (e) {
            console.log(e)
        }
    }

    componentDidMount = () => {
        let { jobId } = this.props.match.params
        let siteId = (new URLSearchParams(this.props.location.search)).get("siteId")!
        let test = (new URLSearchParams(this.props.location.search)).get("test")?.split("-")[1]!
        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
        headers.append('content-type', "application/json");
        fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getDetailedReport/${test}`, {
            headers,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                jobId,
                siteId
            })
        }).then((res) => res.json()).then(json => this.setState({
            data: json.response.result.Items[0].result,
            loading: false,
            test,
            siteId,
            jobId
        }))
    }
    render() {
        console.log(this.state.data)
        return (
            <div className="detailed-report-accordion">
                {this.state.loading ?
                    <Grid>
                        <Grid.Column>
                            <Segment raised>
                                <Placeholder>
                                    <Placeholder.Header>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line length='medium' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                        </Grid.Column>
                    </Grid> :
                    (
                        <div>
                            <div className={"summary-card"}>
                                <Card color='blue' style={{ width: "60%" }}>
                                    <Card.Header>
                                        <div>
                                            <br />
                                            <Grid columns={2}>
                                                <Grid.Column>
                                                    <div style={{ display: 'inline-grid' }}>
                                                        <span style={{ display: 'inline-flex' }}><h4>Site Id - </h4>{this.state.siteId} </span>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <div style={{ display: 'inline-grid' }}>
                                                        <span style={{ display: 'inline-flex' }}><h4>Job Id - </h4>{this.state.jobId}  </span>
                                                    </div>
                                                </Grid.Column>
                                            </Grid>
                                        </div>
                                    </Card.Header>
                                    <Card.Meta>
                                        <Divider />
                                    </Card.Meta>
                                    <Card.Description>
                                        <h3 style={{ margin: "0px" }}>{(services.find(service => service.id === this.state.test))?.name}</h3>
                                        {/* <h3 style={{margin:"0px"}}>{`${this.state.data.totalCount} issues found`}</h3> */}
                                    </Card.Description>
                                    {/* <Card.Content extra>
                                        
                                        <div className='ui two buttons'>
                                            <Button basic color='green' onClick={this.downloadReport} loading={this.state.downloadLoading}>
                                                Download Report
                                            </Button>
                                        </div>
                                    </Card.Content> */}
                                </Card>
                            </div>
                            <div className={"detail-table"}>
                                {this.state.data.length > 0 ? (

                                    <Table striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Url</Table.HeaderCell>
                                                <Table.HeaderCell>Total Objects on Original Image</Table.HeaderCell>
                                                <Table.HeaderCell>Total Objects on Filtered Image</Table.HeaderCell>
                                                <Table.HeaderCell>Boxed Original Image</Table.HeaderCell>
                                                <Table.HeaderCell>Boxed Filered Image</Table.HeaderCell>
                                                <Table.HeaderCell>Status</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {this.state.data.map((item: any) => <Table.Row>
                                                <Table.Cell>
                                                    {item.Url}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.OriginalObjects}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.CbObjects}

                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.s3PathBoxed !== undefined && (item.s3PathBoxed.includes("s3://") || item.s3PathBoxed.includes("gs://")) ? (<div className={"download-screenshot-cell"}>
                                                        <Button loading={this.state.viewImageLoading === item.s3PathBoxed} onClick={() => this.handleImageView(item.s3PathBoxed)}>
                                                            View
                                                        </Button>
                                                        <Button loading={this.state.downloadImageLoading === item.s3PathBoxed} onClick={() => this.handleImageDownload(item.s3PathBoxed)}>
                                                            Download
                                                        </Button>
                                                    </div>) : (item.s3PathBoxed !== undefined ? <div>
                                                        <h4>{item.s3PathBoxed}</h4>
                                                    </div> : <div>
                                                        <h4>N/A</h4>
                                                    </div>)}

                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.s3PathCBBoxed !== undefined && (item.s3PathCBBoxed.includes("s3://") || item.s3PathCBBoxed.includes("gs://")) ? (<div className={"download-screenshot-cell"}>
                                                        <Button loading={this.state.viewImageLoading === item.s3PathCBBoxed} onClick={() => this.handleImageView(item.s3PathCBBoxed)}>
                                                            View
                                                        </Button>
                                                        <Button loading={this.state.downloadImageLoading === item.s3PathCBBoxed} onClick={() => this.handleImageDownload(item.s3PathCBBoxed)}>
                                                            Download
                                                        </Button>
                                                    </div>) : (item.s3PathCBBoxed !== undefined ? <div>
                                                        <h4>{item.s3PathCBBoxed}</h4>
                                                    </div> : <div>
                                                        <h4>N/A</h4>
                                                    </div>)}

                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.CbObjects === item.OriginalObjects ? <span style={{ color: 'green' }}>Passed</span> : <span style={{ color: 'red' }}>Failed</span>}

                                                </Table.Cell>
                                            </Table.Row>)}
                                        </Table.Body>
                                    </Table>

                                ) : <Loader />}
                            </div>
                        </div>
                    )
                }
                <div>
                    <Modal
                        onClose={() => this.setState({
                            viewImage: false
                        })}
                        onOpen={() => this.setState({
                            viewImage: true
                        })}
                        open={this.state.viewImage}
                    >
                        <Modal.Header>Screenshot</Modal.Header>
                        <Modal.Content image>
                            <Image size='large' src={this.state.imageSrc} wrapped />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({
                                viewImage: false
                            })}>Cancel</Button>
                            <Button onClick={() => {
                                this.download(this.state.imageSrc)
                                this.setState({
                                    viewImage: false
                                })
                            }} positive>
                                Download
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default withRouter(OnDemandDetailedAnalysisCB);