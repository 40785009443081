import React from 'react';
import {
    withRouter,
    RouteComponentProps
} from 'react-router-dom'
//import SummaryAccordion from '../../components/accordion'
import { services } from '../../assets/services-metadata'
import { Grid, Placeholder, Segment, Card, Button, Table, Divider } from 'semantic-ui-react'

type params = {
    jobId: string,
}
interface props extends RouteComponentProps<params> {

}

type state = {
    data: {
        thirdPartyUrls: any[],
        s3Path: string,
    },
    loading: boolean,
    service: string
    integrations: any[],
    downloadLoading: boolean,
    siteId: string,
    jobId: string,
    brand: string,
    country: string,
    category: string,
    subCategory: string
}


const {
    REACT_APP_STAGE,
    REACT_APP_SERVICE_DOMAIN,
    REACT_APP_SERVICE_KEY
} = process.env

class OnDemandDetailedAnalysisTPI extends React.Component<props, state>{

    constructor(props: props) {
        super(props)
        this.state = {
            data: {
                thirdPartyUrls: [],
                s3Path: ""
            },
            loading: true,
            service: "thirdparty-integration",
            integrations: [],
            downloadLoading: false,
            siteId: "",
            jobId: "",
            brand: "",
            country: "",
            category: "",
            subCategory: ""
        }
    }

    componentDidMount = () => {
        let { jobId } = this.props.match.params
        let siteId = (new URLSearchParams(this.props.location.search)).get("siteId")!
        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
        headers.append('content-type', "application/json"!);
        fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getDetailedReport/${this.state.service}`, {
            headers,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                jobId,
                siteId
            })
        }).then((res) => res.json()).then(json => this.setState({
            data: json.response.result.Items[0][this.state.service],
            loading: false,
            siteId,
            jobId,
            brand: json.response.result.Items[0].brand,
            country: json.response.result.Items[0].country,
            category: json.response.result.Items[0].division,
            subCategory: json.response.result.Items[0].subCategory,
            integrations: json.response.result.Items[0][this.state.service].thirdPartyUrls
        }))
    }

    downloadReport = async () => {
        try {
            this.setState({
                downloadLoading: true
            })
            const headers = new Headers();
            headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
            let s3Url = await (await fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getAuthS3Url?siteId=${this.state.siteId}&jobId=${this.state.jobId}&service=${this.state.service}`, {
                headers,
                method: 'GET',
                mode: 'cors',
            })).json();
            const link = document.createElement("a");
            console.log("s3Url.response.signedPath", s3Url.response.signedPath)
            link.href = decodeURIComponent(s3Url.response.signedPath);
            link.setAttribute(
                "download",
                `${this.state.jobId}-${this.state.siteId}-${this.state.service}`
            );
            document.body.appendChild(link);
            link.click();
            this.setState({
                downloadLoading: false
            })
        } catch (e) {
            console.log(e)
        }

    }

    render() {
        console.log(this.state.data)
        return (
            <div className="detailed-report-accordion">
                {this.state.loading ?
                    <Grid>
                        <Grid.Column>
                            <Segment raised>
                                <Placeholder>
                                    <Placeholder.Header>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line length='medium' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                        </Grid.Column>
                    </Grid> :
                    (
                        <div>
                            <div className={"summary-card"}>
                                <Card color='blue' style={{ width: "60%" }}>
                                    <Card.Header>
                                        <div>
                                            <br />
                                            <Grid columns={2}>
                                                <Grid.Column>
                                                    <div style={{ display: 'inline-grid' }}>
                                                        <span style={{ display: 'inline-flex' }}><h4>Site Id - </h4>{this.state.siteId} </span>
                                                        <span style={{ display: 'inline-flex' }}><h4>Brand - </h4>{this.state.brand} </span>
                                                        <span style={{ display: 'inline-flex' }}><h4>Category - </h4>{this.state.category} </span>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <div style={{ display: 'inline-grid' }}>
                                                        <span style={{ display: 'inline-flex' }}><h4>Job Id - </h4>{this.state.jobId}  </span>
                                                        <span style={{ display: 'inline-flex' }}><h4>Country - </h4>{this.state.country}  </span>
                                                        <span style={{ display: 'inline-flex' }}><h4>SubCategory - </h4>{this.state.subCategory}  </span>
                                                    </div>
                                                </Grid.Column>
                                            </Grid>
                                        </div>
                                    </Card.Header>
                                    <Card.Meta>
                                        <Divider />
                                    </Card.Meta>

                                    <Card.Description>
                                        <h3 style={{ margin: "0px" }}>{(services.find(service => service.id === this.state.service))?.name}</h3>
                                        <h3 style={{ margin: "0px" }}>{`${this.state.data.thirdPartyUrls.length} integrations found`}</h3>
                                    </Card.Description>
                                    <Card.Content extra>
                                        <div className='ui two buttons'>
                                            <Button basic color='green' onClick={this.downloadReport} loading={this.state.downloadLoading}>
                                                Download Report
                                            </Button>
                                        </div>
                                    </Card.Content>
                                </Card>
                            </div>
                            <div className={"detail-table text-align-center"}>
                                {typeof this.state.integrations !== 'string' && this.state.integrations.length > 0 ? (
                                    <Table striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Third Party Domain</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {this.state.integrations.map(item => <Table.Row>
                                                <Table.Cell>
                                                    {item}
                                                </Table.Cell>
                                            </Table.Row>)}
                                        </Table.Body>
                                    </Table>

                                ) : (typeof this.state.integrations === 'string' ? <h3>{this.state.integrations}</h3> : null)}
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default withRouter(OnDemandDetailedAnalysisTPI);