import {getMainMetricsColor, getLcpColor, getClsColor ,getTbtColor ,getTtiColor , getFcpColor ,getSiColor} from '../components/averageColors';

const services = [
    {
        "id": "Performance",
        "name": "Performance",
        "getColor": function(params) {
            return getMainMetricsColor(params)
        } ,
        "description": "The Performance score is a weighted average of the metric scores. Naturally, more heavily weighted metrics have a bigger effect on your overall Performance score."
    },
    {
        "id": "Accessibility",
        "name": "Accessibility",
        "getColor": function(params) {
            return getMainMetricsColor(params)
        } ,
        "description": "These checks highlight opportunities to improve the accessibility of your web app. Only a subset of accessibility issues can be automatically detected so manual testing is also encouraged."
    },
    {
        "id": "BestPractices",
        "name": "Best Practices",
        "getColor": function(params) {
            return getMainMetricsColor(params)
        } ,
        "description": "Lighthouse analyzes whether the best practices to build a web app is followed or not and then scores accordingly."
    },
    {
        "id": "Seo",
        "name": "Search Engine Optimization",
        "getColor": function(params) {
            return getMainMetricsColor(params)
        } ,
        "description": "These checks ensure that your page is optimized for search engine results ranking. "
    },
    {
        "id": "Fcp",
        "name": "First Contentfull Paint",
        "getColor": function(params) {
            return getFcpColor(params)
        } ,
        "description": "First Contentful Paint (FCP) is one of six metrics tracked in the Performance section of the Lighthouse report. Each metric captures some aspect of page load speed."
    },
    {
        "id": "SpeedIndex",
        "name": "Speed Index",
        "getColor": function(params) {
            return getSiColor(params)
        } ,
        "description": "Speed Index is one of six metrics tracked in the Performance section of the Lighthouse report. Each metric captures some aspect of page load speed."
    },
    {
        "id": "Lcp",
        "name": "Largest Contentful Paint",
        "getColor": function(params) {
            return getLcpColor(params)
        } ,
        "description": "Largest Contentful Paint (LCP) is an important, user-centric metric for measuring perceived load speed because it marks the point in the page load timeline when the page's main content has likely loaded—a fast LCP helps reassure the user that the page is useful."
    },
    {
        "id": "Cls",
        "name": "Cumulative Layout Shift",
        "getColor": function(params) {
            return getClsColor(params)
        } ,
        "description": "Cumulative Layout Shift (CLS) is an important, user-centric metric for measuring visual stability because it helps quantify how often users experience unexpected layout shifts—a low CLS helps ensure that the page is delightful."
    },
    
    {
        "id": "TimeToInteractive",
        "name": "Time to Interactive",
        "getColor": function(params) {
            return getTtiColor(params)
        } ,
        "description": "Time to Interactive (TTI) is one of six metrics tracked in the Performance section of the Lighthouse report. Each metric captures some aspect of page load speed."
    },
    {
        "id": "TotalBlockingTime",
        "name": "Total Blocking Time",
        "getColor": function(params) {
            return getTbtColor(params)
        } ,
        "description": "Total Blocking Time (TBT) is one of the metrics tracked in the Performance section of the Lighthouse report. Each metric captures some aspect of page load speed."
    }
]

export default services