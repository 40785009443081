import { createContext } from 'react';
import type { User } from "firebase/auth";

interface loginData {
  isLoggedIn: boolean;
  user: User | undefined
}

interface loginContext {
  loggedInUser: loginData;
  setLoggedInUser(arg0: loginData): any;
}

export const LoggedInContext = createContext<loginContext>({
  loggedInUser: { isLoggedIn: false, user: undefined },
  setLoggedInUser: () => { },
});