import React from 'react';
import {
    withRouter,
    RouteComponentProps
} from 'react-router-dom'
//import SummaryAccordion from '../../components/accordion'
import { services } from '../../assets/services-metadata'
import { Grid, Placeholder, Segment, Card, Button, Form, Table, Modal, Image, Divider } from 'semantic-ui-react'

type params = {
    jobId: string,
}
interface props extends RouteComponentProps<params> {

}

type state = {
    data: {
        totalCount: any,
        urlFilters: string[],
        codeAAFilters: string[],
        codeAAAFilters: string[],
        [key: string]: any
    },
    loading: boolean,
    service: string,
    siteId: string,
    jobId: string,
    standardSelected: string,
    codeSelected: string,
    urlSelected: string,
    codeOptions: object[],
    urlOptions: object[]
    searchLoading: boolean,
    issues: any[],
    downloadLoading: boolean,
    downloadImageLoading: string,
    viewImageLoading: string,
    imageSrc: string,
    viewImage: boolean,
}


const {
    REACT_APP_STAGE,
    REACT_APP_SERVICE_DOMAIN,
    REACT_APP_SERVICE_KEY
} = process.env

class OnDemandDetailedAnalysis extends React.Component<props, state>{

    constructor(props: props) {
        super(props)
        this.state = {
            data: {
                totalCount: null,
                urlFilters: [],
                codeAAFilters: [],
                codeAAAFilters: [],

            },
            loading: true,
            service: "accessibility-testing",
            standardSelected: "",
            codeSelected: "",
            urlSelected: "",
            codeOptions: [],
            urlOptions: [],
            searchLoading: false,
            issues: [],
            downloadLoading: false,
            downloadImageLoading: "",
            viewImageLoading: "",
            imageSrc: "",
            viewImage: false,
            siteId: "",
            jobId: ""
        }
    }

    componentDidMount = () => {
        let { jobId } = this.props.match.params
        let siteId = (new URLSearchParams(this.props.location.search)).get("siteId")!
        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
        headers.append('content-type', "application/json");
        fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getDetailedReportFilters`, {
            headers,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                jobId,
                siteId
            })
        }).then((res) => res.json()).then(json => this.setState({
            data: json.response,
            loading: false,
            siteId,
            jobId
        }))
    }

    componentDidUpdate = (_prevProps: any, prevState: any) => {
        if (this.state.standardSelected !== prevState.standardSelected) {
            if (this.state.standardSelected === "AA") {
                let codeOptions = new Set()
                this.state.data.codeAAFilters.map(item => codeOptions.add(item.split("-")[0]))
                this.setState({
                    codeOptions: Array.from(codeOptions).map(item => {
                        return {
                            text: item,
                            value: item
                        }
                    }),
                    codeSelected: ""
                })
            } else if (this.state.standardSelected === "AAA") {
                let codeOptions = new Set()
                this.state.data.codeAAAFilters.map(item => codeOptions.add(item.split("-")[0]))
                this.setState({
                    codeOptions: Array.from(codeOptions).map(item => {
                        return {
                            text: item,
                            value: item
                        }
                    }),
                    codeSelected: ""
                })
            }
        }
        if (this.state.codeSelected !== prevState.codeSelected) {
            console.log(this.state.codeSelected)
            if (this.state.standardSelected === "AA") {
                let urlOptions = new Set()
                this.state.data.codeAAFilters.map((element): void => {
                    if (element.includes(this.state.codeSelected)) {
                        if (element.split("-").length > 2) {
                            urlOptions.add(element.split("-").slice(1).join("-"))
                        } else {
                            urlOptions.add(element.split("-")[1])
                        }

                    }
                })
                this.setState({
                    urlOptions: Array.from(urlOptions).map(item => {
                        return {
                            text: item,
                            value: item
                        }
                    })
                })
            } else if (this.state.standardSelected === "AAA") {
                let urlOptions = new Set()
                this.state.data.codeAAAFilters.map((element): void => {
                    if (element.includes(this.state.codeSelected)) {
                        if (element.split("-").length > 2) {
                            urlOptions.add(element.split("-").slice(1).join("-"))
                        } else {
                            urlOptions.add(element.split("-")[1])
                        }

                    }
                })
                this.setState({
                    urlOptions: Array.from(urlOptions).map(item => {
                        return {
                            text: item,
                            value: item
                        }
                    })
                })
            }

        }
    }

    downloadReport = async () => {
        try {
            this.setState({
                downloadLoading: true
            })
            const headers = new Headers();
            headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
            let s3Url = await (await fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getAuthS3Url?siteId=${this.state.siteId}&jobId=${this.state.jobId}&service=${this.state.service}`, {
                headers,
                method: 'GET',
                mode: 'cors',
            })).json();
            const link = document.createElement("a");
            link.href = decodeURIComponent(s3Url.response.signedPath);
            link.setAttribute(
                "download",
                `${this.state.jobId}-${this.state.siteId}-${this.state.service}`
            );
            document.body.appendChild(link);
            link.click();
            this.setState({
                downloadLoading: false
            })
        } catch (e) {
            console.log(e)
        }
    }
    download: Function = async (downloadablePath: string) => {
        try {
            const link = document.createElement("a");
            link.href = downloadablePath;
            link.setAttribute(
                "download",
                `image-${this.state.service}`
            );
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            console.log(e)
        }
    }

    handleImageDownload: Function = async (s3Path: string) => {
        try {
            this.setState({
                downloadImageLoading: s3Path
            })
            const headers = new Headers();
            headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
            let s3Url = await (await fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getSignedUrlImage`, {
                headers,
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    s3Path
                })
            })).json();
            this.download(s3Url.response.signedPath)
            this.setState({
                downloadImageLoading: ""
            })
        } catch (e) {
            console.log(e)
        }
    }

    handleImageView: Function = async (s3Path: string) => {
        try {
            this.setState({
                viewImageLoading: s3Path
            })
            const headers = new Headers();
            headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
            let s3Url = await (await fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getSignedUrlImage`, {
                headers,
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    s3Path
                })
            })).json();

            this.setState({
                viewImageLoading: "",
                viewImage: true,
                imageSrc: s3Url.response.signedPath
            })
        } catch (e) {
            console.log(e)
        }
    }

    handleSearch = async () => {
        this.setState({
            searchLoading: true
        })
        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
        headers.append('content-type', "application/json");
        fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/results/getDetailedReport/${this.state.service}`, {
            headers,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                jobId: this.state.jobId,
                siteId: this.state.siteId,
                code: this.state.codeSelected,
                url: this.state.urlSelected,
            })
        }).then((res) => res.json()).then(json => {
            this.setState({
                searchLoading: false,
                issues: json.response.result.Items.length === 0 ? `No Issue are found for the group code ${this.state.codeSelected} on the url ${this.state.urlSelected}` : json.response.result.Items[0].issues
            })
        })
    }

    render() {
        return (
            <div className="detailed-report-accordion">
                {this.state.loading ?
                    <Grid>
                        <Grid.Column>
                            <Segment raised>
                                <Placeholder>
                                    <Placeholder.Header>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line length='medium' />
                                        <Placeholder.Line length='short' />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                        </Grid.Column>
                    </Grid> :
                    (
                        <div>
                            <div className={"summary-card"}>
                                <Card color='blue' style={{ width: "60%" }}>
                                    <Card.Header>
                                        <div>
                                            <br />
                                            <Grid columns={2}>
                                                <Grid.Column>
                                                    <div style={{ display: 'inline-grid' }}>
                                                        <span style={{ display: 'inline-flex' }}><h4>Site Id - </h4>{this.state.data.sk} </span>
                                                        <span style={{ display: 'inline-flex' }}><h4>Brand - </h4>{this.state.data.brand} </span>
                                                        <span style={{ display: 'inline-flex' }}><h4>Category - </h4>{this.state.data.division} </span>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <div style={{ display: 'inline-grid' }}>
                                                        <span style={{ display: 'inline-flex' }}><h4>Job Id - </h4>{this.state.data.pk}  </span>
                                                        <span style={{ display: 'inline-flex' }}><h4>Country - </h4>{this.state.data.country}  </span>
                                                        <span style={{ display: 'inline-flex' }}><h4>SubCategory - </h4>{this.state.data.subCategory}  </span>
                                                    </div>
                                                </Grid.Column>
                                            </Grid>
                                        </div>
                                    </Card.Header>
                                    <Card.Meta>
                                        <Divider />
                                    </Card.Meta>
                                    <Card.Description>
                                        <h3 style={{ margin: "0px" }}>{(services.find(service => service.id === this.state.service))?.name}</h3>
                                        <h3 style={{ margin: "0px" }}>{`${this.state.data.totalCount} issues found`}</h3>
                                    </Card.Description>
                                    <Card.Content extra>

                                        <div className='ui two buttons'>
                                            <Button basic color='green' onClick={this.downloadReport} loading={this.state.downloadLoading}>
                                                Download Report
                                            </Button>
                                        </div>
                                    </Card.Content>
                                </Card>
                            </div>
                            <div>
                                <Grid centered >
                                    <Grid.Column width={3}>
                                        <Form.Select
                                            fluid
                                            onChange={(e: any, { value }: any) => this.setState({ standardSelected: value })}
                                            label='Select the Standard'
                                            options={[
                                                { text: 'AA', value: 'AA' }, { text: 'AAA', value: 'AAA' }
                                            ]}
                                            placeholder='Standard'
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <Form.Select
                                            fluid
                                            onChange={(e: any, { value }: any) => this.setState({ codeSelected: value })}
                                            label='Select the Code'
                                            options={this.state.codeOptions}
                                            placeholder='Code'
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <Form.Select
                                            fluid
                                            onChange={(e: any, { value }: any) => this.setState({ urlSelected: value })}
                                            label='Select the Url'
                                            options={this.state.urlOptions}
                                            placeholder='Url'
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                        <Button className={"submit"} style={{ 'margin-top': '28%' }} onClick={this.handleSearch} loading={this.state.searchLoading} >Search</Button>
                                    </Grid.Column>
                                </Grid>
                            </div>
                            <div className={"detail-table"}>
                                {typeof this.state.issues !== 'string' && this.state.issues.length > 0 ? (

                                    <Table striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Issue</Table.HeaderCell>
                                                <Table.HeaderCell>Selector</Table.HeaderCell>
                                                <Table.HeaderCell>Screenshot</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {this.state.issues.map((item: any) => <Table.Row>
                                                <Table.Cell>
                                                    {item.message}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.selector}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.screenShotPath !== undefined && (item.screenShotPath.includes("gs://") || item.screenShotPath.includes("s3://")) ? (<div className={"download-screenshot-cell"}>
                                                        <Button loading={this.state.viewImageLoading === item.screenShotPath} onClick={() => this.handleImageView(item.screenShotPath)}>
                                                            View
                                                        </Button>
                                                        <Button loading={this.state.downloadImageLoading === item.screenShotPath} onClick={() => this.handleImageDownload(item.screenShotPath)}>
                                                            Download
                                                        </Button>
                                                    </div>) : (item.screenShotPath !== undefined ? <div>
                                                        <h4>{item.screenShotPath}</h4>
                                                    </div> : <div>
                                                        <h4>N/A</h4>
                                                    </div>)}

                                                </Table.Cell>
                                            </Table.Row>)}
                                        </Table.Body>
                                    </Table>

                                ) : (typeof this.state.issues === 'string' ? <h3>{this.state.issues}</h3> : null)}
                            </div>
                        </div>
                    )
                }
                <div>
                    <Modal
                        onClose={() => this.setState({
                            viewImage: false
                        })}
                        onOpen={() => this.setState({
                            viewImage: true
                        })}
                        open={this.state.viewImage}
                    >
                        <Modal.Header>Screenshot</Modal.Header>
                        <Modal.Content image>
                            <Image size='large' src={this.state.imageSrc} wrapped />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({
                                viewImage: false
                            })}>Cancel</Button>
                            <Button onClick={() => {
                                this.download(this.state.imageSrc)
                                this.setState({
                                    viewImage: false
                                })
                            }} positive>
                                Download
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default withRouter(OnDemandDetailedAnalysis);