import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import OnDemandRunTask from '../../container/on-demand/onDemandRunTask';
import OnDemandHistory from '../../container/on-demand/onDemandHistory';
import OnDemandSummary from '../../container/on-demand/onDemandSummary';
import OnDemandDetailedAnalysis from '../../container/on-demand/onDemandDetailedAnalysis';
import OnDemandDetailedAnalysisTPI from '../../container/on-demand/onDemandDetailedAnalysisTPI';
import ComparisonRunTask from '../../container/comparison/comparisonRunTask';
import ComparisonHistory from '../../container/comparison/comparisonHistory';
import MassExecutionReport from '../../container/mass-execution/massExecutionReportPage';
import MassExecutionSummary from '../../container/mass-execution/massExecutionSummary';
import TrendsGraphLighthouse from '../../container/trends/trendsGraphLighthouse';
import OnDemandDetailedAnalysisLH3G from '../../container/on-demand/onDemandDetailedAnalysisLH3G';
import OnDemandDetailedAnalysisLHCable from '../../container/on-demand/onDemandDetailedAnalysisLHCable';
import OnDemandDetailedAnalysisCB from '../../container/on-demand/onDemandDetailedAnalysisCB';
import OnDemandDetailedAnalysisSEO from '../../container/on-demand/onDemandDetailedAnalysisSEO';
import Dashboard from '../../container/trends/dashboard';
import Login from '../../container/login/login';
import { useContext, useEffect } from 'react';
import { LoggedInContext } from '../../Login-Context'
import { decode } from 'jsonwebtoken';

const Main = () => {


    const { loggedInUser, setLoggedInUser } = useContext(LoggedInContext);
    const { isLoggedIn } = loggedInUser

    let loginData: any;

    useEffect(() => {
        setLoggedInUser({ isLoggedIn: loginData?.isLoggedIn, user: loginData?.user })
    }, [setLoggedInUser, loginData]);

    function checkAlreadyLoggedIn() {
        const sessionLoginData: any = localStorage.getItem('loginData')
        if (sessionLoginData) {
            const { exp } = decode(sessionLoginData) as any;
            if (Date.now() < exp * 1000) {
                const sessionUser: any = JSON.parse(localStorage.getItem('sessionUser') as any)
                loginData = {
                    user: sessionUser,
                    isLoggedIn: true
                }
                return true
            }
        }
        return false
    }

    return (
        <Switch>
            <Route exact path="/"
                render={(props) =>
                    checkAlreadyLoggedIn() ? <Redirect to="/on-demand/run-job" />
                        : <Login {...props} redirectTo="/on-demand/run-job" />
                } />

            <Route exact path="/on-demand" render={(props) =>
                checkAlreadyLoggedIn() ? <OnDemandRunTask {...props} />
                    : <Login {...props} redirectTo="/on-demand/run-job" />
            } />


            <Route exact path="/comparison">
                {checkAlreadyLoggedIn() ? <Redirect to="/comparison/run-job" />
                    : <Login redirectTo="/comparison/run-job" />}
            </Route>
            <Route path="/trends/dashboard" render={(props) => (
                checkAlreadyLoggedIn()
                    ? <Dashboard {...props} />
                    : <Login redirectTo="/trends/dashboard" />
            )} />;

            <Route path="/on-demand/run-job" render={(props) =>
                checkAlreadyLoggedIn() ? <OnDemandRunTask {...props} />
                    : <Login {...props} redirectTo="/on-demand/run-job" />
            } />

            <Route path="/comparison/run-job" render={(props) =>
                checkAlreadyLoggedIn() ? <ComparisonRunTask {...props} />
                    : <Login {...props} redirectTo="/comparison/run-job" />
            } />

            <Route path="/on-demand/history" render={(props) =>
                checkAlreadyLoggedIn() ? <OnDemandHistory {...props} />
                    : <Login {...props} redirectTo="/on-demand/history" />
            } />
            <Route path="/comparison/history" render={(props) =>
                checkAlreadyLoggedIn() ? <ComparisonHistory {...props} />
                    : <Login {...props} redirectTo="/comparison/history" />
            } />

            <Route path="/on-demand/summary/lighthouse-3g/detailedAnalysis/:jobId"
                render={(props) => {
                    const jobId = props.match.params.jobId
                    return checkAlreadyLoggedIn() ? <OnDemandDetailedAnalysisLH3G {...props} />
                        : <Login {...props} redirectTo={`/on-demand/summary/lighthouse-3g/detailedAnalysis/${jobId}`} />
                }} />

            <Route path="/on-demand/summary/lighthouse-cable/detailedAnalysis/:jobId"
                render={(props) => {
                    const jobId = props.match.params.jobId
                    return checkAlreadyLoggedIn() ? <OnDemandDetailedAnalysisLHCable {...props} />
                        : <Login {...props} redirectTo={`/on-demand/summary/lighthouse-cable/detailedAnalysis/${jobId}`} />
                }} />

            <Route path="/on-demand/summary/accessibility-testing/detailedAnalysis/:jobId"
                render={(props) => {
                    const jobId = props.match.params.jobId
                    return checkAlreadyLoggedIn() ? <OnDemandDetailedAnalysis {...props} />
                        : <Login {...props} redirectTo={`/on-demand/summary/accessibility-testing/detailedAnalysis/${jobId}`} />
                }} />

            <Route path="/on-demand/summary/thirdparty-integration/detailedAnalysis/:jobId"
                render={(props) => {
                    const jobId = props.match.params.jobId
                    return checkAlreadyLoggedIn() ? <OnDemandDetailedAnalysisTPI {...props} />
                        : <Login {...props} redirectTo={`/on-demand/summary/thirdparty-integration/detailedAnalysis/${jobId}`} />
                }} />
            <Route path="/on-demand/summary/color-blind-test-att/detailedAnalysis/:jobId"
                render={(props) => {
                    const jobId = props.match.params.jobId
                    return checkAlreadyLoggedIn() ? <OnDemandDetailedAnalysisCB {...props} />
                        : <Login {...props} redirectTo={`/on-demand/summary/color-blind-test-att/detailedAnalysis/${jobId}`} />
                }} />

            <Route path="/on-demand/summary/lighthouse-seo/detailedAnalysis/:jobId"
                render={(props) => {
                    const jobId = props.match.params.jobId
                    return checkAlreadyLoggedIn() ? <OnDemandDetailedAnalysisSEO {...props} />
                        : <Login {...props} redirectTo={`/on-demand/summary/lighthouse-seo/detailedAnalysis/${jobId}`} />
                }} />

            <Route path="/on-demand/summary/:jobId"
                render={(props) => {
                    const jobId = props.match.params.jobId
                    return checkAlreadyLoggedIn() ? <OnDemandSummary {...props} />
                        : <Login {...props} redirectTo={`/on-demand/summary/${jobId}`} />
                }} />

            <Route exact path="/mass-execution">
                {checkAlreadyLoggedIn() ? <Redirect to="/mass-execution/report" />
                    : <Login redirectTo="/mass-execution/report" />}
            </Route>

            <Route exact path="/mass-execution">
                {checkAlreadyLoggedIn() ? <Redirect to="/mass-execution/report" />
                    : <Login redirectTo="/mass-execution/report" />}
            </Route>

            <Route path="/mass-execution/report" render={(props) =>
                checkAlreadyLoggedIn() ? <MassExecutionReport {...props} />
                    : <Login {...props} redirectTo="/mass-execution/report" />
            } />
            <Route path="/mass-execution/summary" render={(props) =>
                checkAlreadyLoggedIn() ? <MassExecutionSummary {...props} />
                    : <Login {...props} redirectTo="/mass-execution/summary" />
            } />
            <Route path="/trends/dashboard/lighthouse" render={(props) =>
                checkAlreadyLoggedIn() ? <TrendsGraphLighthouse {...props} />
                    : <Login {...props} redirectTo="/trends/dashboard/lighthouse" />
            } />

            <Route path="*">
                <Redirect to="/on-demand/run-job" />
            </Route>
        </Switch>
    );
}

export default Main;