import React from 'react';
import Navigation from "../../components/navigation";
import items from '../../assets/massexecution-metadata.json';
import { Form, Button } from 'semantic-ui-react'
import '../container.css'

type props = {
}
type state = {
    siteIds: [],
    selectedSiteId: string
    loading: boolean
}

const {
    REACT_APP_STAGE,
    REACT_APP_SERVICE_DOMAIN,
    REACT_APP_SERVICE_KEY
} = process.env

class MassExecutionReport extends React.Component<props, state> {

    constructor(props: props) {
        super(props);
        this.state = {
            siteIds: [],
            selectedSiteId: "",
            loading: false
        }
    }

    componentDidMount = async () => {
        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
        fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/siteinfo/getFullSiteIds`, {
            headers,
            method: 'GET',
            mode: 'cors'
        }).then((res) => res.json()).then(json => this.setState({
            siteIds: json.response.siteIds.filter((item: any) => item.cxx)
        }))
    }

    handleDropDown = async (e: any, { value }: any) => {
        this.setState({
            selectedSiteId: value
        })
    }

    render() {
        return (
            <div className="onDemand-outerdiv">
                <Navigation  items={items} />
                <div className="max-width">
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Select
                                fluid
                                label='Select Site ID'
                                onChange={this.handleDropDown.bind(this)}
                                options={this.state.siteIds.map((item:any) => {
                                    return {
                                        text: item.siteId,
                                        value: item.siteId
                                    }
                                })}
                                placeholder='Site Id'
                            />
                        </Form.Group>
                        <br />
                        <Button className={"submit"} loading={this.state.loading} onClick={() => window.location.href=`/mass-execution/summary?siteId=${decodeURI(this.state.selectedSiteId)}`}>Get Report</Button>
                    </Form>
                </div>
            </div>
        )
    }
}

export default MassExecutionReport;