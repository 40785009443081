import React from 'react';
import Navigation from "../../components/navigation";
import items from '../../assets/ondemand-metadata.json';
import { services, colorBlindness } from '../../assets/services-metadata';
import { Form, Button, Confirm, Grid, Checkbox } from 'semantic-ui-react'
import '../container.css'

type props = {
}
type state = {
    siteIds: [],
    loading: boolean,
    emailError: any,
    selectedSiteId: string,
    services: Set<string>,
    dropDownError: any,
    servicesError: any,
    portalOpen: boolean,
    submitMessage: string,
    submitId: number | string,
    blockThirdParty: boolean
}

const {
    REACT_APP_STAGE,
    REACT_APP_SERVICE_DOMAIN,
    REACT_APP_SERVICE_KEY
} = process.env
class OnDemandRunTask extends React.Component<props, state> {
    inputEmail: any = ""
    inputBlock: any = ""
    constructor(props: props) {
        super(props);
        this.state = {
            siteIds: [],
            loading: false,
            selectedSiteId: "",
            emailError: false,
            services: new Set(),
            dropDownError: false,
            servicesError: false,
            portalOpen: false,
            submitMessage: "",
            submitId: "",
            blockThirdParty: false
        }

    }

    componentDidMount = async () => {

        const headers = new Headers();
        headers.append('x-api-key', REACT_APP_SERVICE_KEY!);
        fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/siteinfo/getSiteIds`, {
            headers,
            method: 'GET',
            mode: 'cors'
        }).then((res) => res.json()).then(json => this.setState({
            siteIds: json.response.siteIds
        }))
    }


    handleSubmit = async () => {
        this.setState({
            loading: true
        })
        let dropDownError = true, servicesError = true, emailError = true
        if (this.state.selectedSiteId === "") {
            this.setState({
                dropDownError: "Site Id not selected"
            })
        } else {
            this.setState({
                dropDownError: false
            })
            dropDownError = false
        }
        if (Array.from(this.state.services).length === 0) {
            this.setState({
                servicesError: "Select the service to be run"
            })
        } else {
            this.setState({
                servicesError: false
            })
            servicesError = false
        }
        if (!(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/.test(this.inputEmail))) {
            this.setState({
                emailError: "Email not valid"
            })
        } else {
            this.setState({
                emailError: false
            })
            emailError = false
        }
        if (!emailError && !dropDownError && !servicesError) {
            const headers = new Headers();
            headers.append('x-api-key', REACT_APP_SERVICE_KEY!);

            let body = JSON.stringify({
                siteId: this.state.selectedSiteId,
                services: Array.from(this.state.services).join(","),
                email: this.inputEmail,
                executionType: 'on-demand'
            })
            if (this.state.blockThirdParty) {
                body = JSON.stringify({
                    siteId: this.state.selectedSiteId,
                    services: Array.from(this.state.services).join(","),
                    email: this.inputEmail,
                    executionType: 'on-demand',
                    blockThirdParty: true,
                    thirdPartyUrls: this.inputBlock === "" ? [] : this.inputBlock.split(",").map((item: any) => item.trim())
                })
            }
            let res = await (await fetch(`${REACT_APP_SERVICE_DOMAIN}/${REACT_APP_STAGE}/start`, {
                headers,
                method: 'POST',
                mode: 'cors',
                body
            })).json()

            this.setState({
                portalOpen: true,
                submitMessage: res.message,
                submitId: res.response.jobId
            })
        }
        this.setState({
            loading: false
        })
    }

    handleEmailChange = async (e: any, { value }: any) => {
        this.inputEmail = value
        // if(!(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/.test(value)) && this.state.email.length > 0){
        //     this.setState({
        //         emailError: "Enter valid comma seperated email"
        //     })
        // }else{
        //     this.setState({
        //         emailError: false
        //     })
        // }
    }

    handleBlockUrl = async (e: any, { value }: any) => {
        this.inputBlock = value
    }
    handleDropDown = async (e: any, { value }: any) => {
        this.setState({
            selectedSiteId: value
        })
    }

    handlePortalConfirm = () => {
        this.setState({
            portalOpen: false
        })
    }                           

    handleSelect = async (data: any) => {
        let services = this.state.services;
       
        if (services.has(data)) {
            
            services.delete(data)
        } else {
            services.add(data)
        } 
        if (!(services.has("lighthouse-3g") || services.has("lighthouse-cable"))) {
            this.setState({
                blockThirdParty: false
            })
        }
        this.setState({
            services
        })
    }

    render() {
        return (
            <div className="onDemand-outerdiv">
                <Navigation items={items} />
                <div className="max-width">
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Select
                                search
                                fluid
                                onChange={this.handleDropDown.bind(this)}
                                label='Select the Site ID on which you want to run he test'
                                options={this.state.siteIds.map(item => {
                                    return {
                                        text: item,
                                        value: item
                                    }
                                })}
                                error={this.state.dropDownError}
                                placeholder='Site Id'
                            />
                        </Form.Group>
                        <Form.Input
                            fluid
                            label='Enter Email'
                            placeholder='Email'
                            id='form-input-email'
                            ref={(input: any) => this.inputEmail = input}
                            onChange={this.handleEmailChange.bind(this)}
                            //value={this.state.email}
                            error={this.state.emailError}
                        />
                        <Confirm
                            open={this.state.portalOpen}
                            onCancel={this.handlePortalConfirm}
                            content={this.state.submitMessage.includes("Success") ? "The Job is triggered successfully. The Job Id is " + this.state.submitId : "An Error occured while submiting the job. Please check the parameters again "}
                            onConfirm={this.handlePortalConfirm}
                        />
                        <Form.Group grouped>
                            <label style={{ paddingBottom: '2em', fontSize: 'medium' }}><b>Services</b></label>
                            <br />
                            <br />
                            <Grid columns={2}>
                                <Grid.Column style={{ 'padding-bottom': '0px', 'padding-top': '0px' }}><Form.Field label={"Accessibility Testing using Pa11y"} control='input' error={this.state.servicesError} type='checkbox' onChange={this.handleSelect.bind(this, "accessibility-testing")} /></Grid.Column>
                                <Grid.Column style={{ 'padding-bottom': '0px', 'padding-top': '0px' }}><Form.Field label={"Third Party Integration"} control='input' error={this.state.servicesError} type='checkbox' onChange={this.handleSelect.bind(this, "thirdparty-integration")} /></Grid.Column>
                                <Grid.Column style={{ 'padding-bottom': '0px', 'padding-top': '0px' }}>
                                    <Form.Field label={"Lighthouse 3G"} control='input' error={this.state.servicesError} type='checkbox' onChange={this.handleSelect.bind(this, "lighthouse-3g")} />


                                </Grid.Column>


                                <Grid.Column style={{ 'padding-bottom': '0px', 'padding-top': '0px' }}>
                                    <Form.Field label={"Lighthouse Cable"} control='input' error={this.state.servicesError} type='checkbox' onChange={this.handleSelect.bind(this, "lighthouse-cable")} />

                                </Grid.Column>


                                <Grid.Column style={{ 'padding-bottom': '0px', 'padding-top': '0px' }}><Form.Field label={'SEO'} control='input' type='checkbox' onChange={this.handleSelect.bind(this, "lighthouse-seo")}  /></Grid.Column>
                                <Grid.Column width={10} style={{ 'padding-bottom': '0px', 'padding-top': '0px' }}>  <div>
                                    <Form.Field label={"Block Third Party Calls (Only for Lighthouse 3G + Cable)"} control='input' error={this.state.servicesError} type='checkbox' onChange={() => {
                                        this.setState({
                                            blockThirdParty: !this.state.blockThirdParty
                                        })
                                    }} disabled={!(this.state.services.has("lighthouse-3g") || this.state.services.has("lighthouse-cable"))} 
                                    checked={this.state.blockThirdParty}
                                    />
                                    <div style={{ textAlign: 'left', fontSize: 'smaller' }}>
                                        <Form.Input
                                            disabled={!(this.state.services.has("lighthouse-3g") || this.state.services.has("lighthouse-cable"))}
                                            fluid
                                            label='Enter Comma Seperated Url Pattern to Block. &nbsp; Example - *url.com*, *url2.com (Only for Lighthouse 3G + Cable)'
                                            placeholder="*"
                                            id='form-input-re'
                                            ref={(input: any) => this.inputBlock = input}
                                            onChange={this.handleBlockUrl.bind(this)}
                                        //value={this.state.email}
                                        />
                                    </div>
                                </div> </Grid.Column>
                            </Grid>
                            <br />
                            <br />
                            <label style={{ paddingBottom: '2em' }}>Accessibility Testing(Color Blindness)</label>
                            <br />
                            <br />
                            <Grid columns={2}>
                                {colorBlindness.map(i => <Grid.Column style={{ 'padding-bottom': '0px', 'padding-top': '0px' }}><Form.Field label={i.name} control='input' error={this.state.servicesError} type='checkbox' onChange={this.handleSelect.bind(this, i.id)} /></Grid.Column>)}
                                <Grid.Column style={{ 'padding-bottom': '0px', 'padding-top': '0px' }}></Grid.Column>
                            </Grid>
                        </Form.Group>
                        <br />
                        <Button className={"submit"} onClick={this.handleSubmit} loading={this.state.loading}>Submit</Button>
                    </Form>
                </div>
            </div>
        )
    }
}

export default OnDemandRunTask;




