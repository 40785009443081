import React from 'react';
import Header from '../components/header';
import Router from '../components/routers/mainRouter'
import services from '../assets/path-metadata.json'

type state = {
    prefix:string
}

type props = {

}

class Main extends React.Component<props, state>{
    constructor(props:props){
        super(props)
        this.state = {
            prefix:""
        }
    }
    handlePrefix = (prefix: string) => {
        this.setState({
            prefix
        })
    }

    componentDidMount = () => {
        const url = window.location.pathname;
        const prefix: any = services.find(i => url.includes(i.path))?.prefix
        this.setState({
            prefix
        })
    }
    render(){
        console.log(this.state.prefix)
        return(
            <div>
                <Header prefix={this.state.prefix === ""? 'on-demand': this.state.prefix} handlePrefix={this.handlePrefix}/>
                <Router />
            </div>
        )
    }
}

export default Main;