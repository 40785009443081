const services = [
    {
        "id":"accessibility-testing",
        "name":"Accessibility Testing using Pa11y",
        "description": function(params) {
            
            return params['accessibility-testing'].AACount === undefined?'The job is in progress':`Found ${params["accessibility-testing"].AACount} issues for AA standard and ${params["accessibility-testing"].AAACount} issues for AAA standard`
        }
    },{
        "id":"thirdparty-integration",
        "name":"Third Party Integration",
        "description": function(params) {
            return  params['thirdparty-integration'].count === undefined?'The job is in progress':`Found ${params["thirdparty-integration"].count} integrations`
        }
    },{
        "id":"lighthouse-3g",
        "name":"Lighthouse 3G",
        "description": function(params) {
            
            return "The URLs are running with 3G configurations"
        }
    },{
        "id":"lighthouse-cable",
        "name":"Lighthouse Cable",
        "description": function(params) {
            return   "The URLs are running with Cable configurations"
        }
    },
    {
        "id":"lighthouse-seo",
        "name":"Search Engine Optimization",
        "description": function(params) {
            return   "The urls are running on lighthouse to get the seo score"
        }
    }
]  

const colorBlindness = [
    {
        "id":"colorblindness-protanopia",
        "name":"Protanopia",
        "description": function(params) {
            return  `The Objects are detected on the original page using an ML model and then color blindness simulation of type protanopia is applied.`
        }
    }, {
        "id":"colorblindness-deuteranopia",
        "name":"Deuteranopia",
        "description": function(params) {
            return  `The Objects are detected on the original page using an ML model and the color blindness simulation of type deuteranopia is applied.`
        }
    }, {
        "id":"colorblindness-tritanopia",
        "name":"Tritanopia",
        "description": function(params) {
            return  `The Objects are detected on the original page using an ML model and the color blindness simulation of type tritanopia is applied.`
        }
    }, {
        "id":"colorblindness-protanomaly",
        "name":"Protanomaly",
        "description": function(params) {
            return  `The Objects are detected on the original page using an ML model and the color blindness simulation of type protanomaly is applied.`
        }
    }, {
        "id":"colorblindness-deuteranomaly",
        "name":"Deuteranomaly",
        "description": function(params) {
            return  `The Objects are detected on the original page using an ML model and the color blindness simulation of type deuteranomaly is applied.`
        }
    }, {
        "id":"colorblindness-tritanomaly",
        "name":"Tritanomaly",
        "description": function(params) {
            return  `The Objects are detected on the original page using an ML model and the color blindness simulation of type tritanomaly is applied.`
        }
    }
]

export  {
    services,
    colorBlindness
}